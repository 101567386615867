import { cn } from "../../helpers";
import { TextareaProps } from "../../types";

const Textarea = (props: TextareaProps) => {
    const { className, value, error, register, rows = 4, ...rest } = props;

    return (
        <textarea
            value={value}
            autoComplete="on"
            className={cn({
                "w-full rounded-md border border-gray-300 placeholder-gray-400":
                    true,
                "px-4 py-4 text-sm text-gray-700": true,
                "focus:outline-none focus:border-primary focus:ring focus:ring-primary/20":
                    true,
                "disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-400":
                    true,
                "border-red-500": !!error,
                [className || ""]: true
            })}
            {...register}
            rows={rows}
            {...rest}
        />
    );
};

export default Textarea;
