import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ANDROID_URL, IOS_URL } from "../constants";
import { Platform } from "../helpers";

const Share = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const link = document.createElement("a");
        if (Platform.isIos()) {
            link.href = IOS_URL;
            link.click();
        } else if (Platform.isAndroid()) {
            link.href = ANDROID_URL;
            link.click();
        } else {
            navigate("/");
        }
    }, [navigate]);

    return null;
};

export default Share;
