// import useGpsPosition from "../hooks/useGpsPosition.ts";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import BsWhatsapp from "./icons/BsWhatsapp.tsx";
import LuPhoneCallIcon from "./icons/LuPhoneCallIcon.tsx";

const SettingServiceClient = () => {
    // const { position } = useGpsPosition();
    const { t } = useTranslation();

    const savContact = useMemo(() => {
        return {
            whatsapp: {
                value: "+221 771902641",
                label: "+221 771902641"
            },
            call: {
                value: "+221 771902641",
                label: "+221 771902641"
            }
        };

        /*if (!position?.countryCode) return value;

        if (["sn", "ml", "bf"].includes(position?.countryCode.toLowerCase())) {
            return value;
        }

        if ("ci" === position?.countryCode) {
            return {
                value: "+2250501667575",
                label: "+225 0501667575"
            };
        }

        if (["bj", "tg"].includes(position?.countryCode.toLowerCase())) {
            return {
                value: "+22991317777",
                label: "+229 91317777"
            };
        }

        return value;*/
    }, []);

    return (
        <div className="space-y-8">
            <p className="text-gray-600">
                {t(
                    "Notre service client est disponible du Lundi au Vendredi de 8h30 à 17h30"
                )}
            </p>

            <div className="w-full mx-auto lg:w-2/5 space-y-5">
                <a
                    href={`https://wa.me/${savContact.whatsapp.value}`}
                    target="_blank"
                    className="w-full bg-gray-100 py-4 px-3 flex items-center justify-center space-x-4 rounded-md"
                    rel="noreferrer"
                >
                    <BsWhatsapp className="fill-green-500" />
                    <span>{savContact.whatsapp.label}</span>
                </a>

                <a
                    href={`tel:${savContact.call.value}`}
                    className="w-full bg-gray-100 py-4 px-3 flex items-center justify-center space-x-4 rounded-md"
                >
                    <LuPhoneCallIcon />
                    <span>{savContact.call.label}</span>
                </a>
            </div>
        </div>
    );
};

export default SettingServiceClient;
