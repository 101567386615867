import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import config from "../config";
import { STORAGE } from "../constants";
import ENDPOINTS from "../constants/endpoint.ts";
import {
    auth,
    clearActivityTimer,
    loadAuthServiceHost,
    storage
} from "../helpers";
import { logout } from "../store/authSlice.ts";
import { resetNotificationStore } from "../store/notificationSlice.ts";
import { resetTransferStore } from "../store/transferSlice.ts";
import { setLogoutLoading } from "../store/uiSlice.ts";
import { UserType } from "../types";

import useToast from "./useToast.ts";

const useLogout = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { successToast, errorToast } = useToast();
    const navigate = useNavigate();

    const resetPrivacyPolicyStorage = useCallback(
        (user: UserType | undefined) => {
            if (user && !user?.cgu_validated_at) {
                storage().setItem(
                    STORAGE.ALREADY_SEE_PRIVACY_POLICY,
                    JSON.stringify(false)
                );
            }
        },
        []
    );

    const removeCompleteKycAlertData = useCallback(() => {
        storage().removeItem(STORAGE.ALERT_COMPLETE_PROFILE);
    }, []);

    const resetStorage = useCallback(
        (user: UserType | undefined) => {
            resetPrivacyPolicyStorage(user);
            removeCompleteKycAlertData();
            storage().removeItem(STORAGE.CARD_WALLET_FEATURE);
        },
        [removeCompleteKycAlertData, resetPrivacyPolicyStorage]
    );

    const clientLogout = useCallback(
        (logoutMessage?: string, onSuccess?: () => void) => {
            const user = auth()?.user;
            logoutMessage && successToast(logoutMessage);
            dispatch(logout());
            dispatch(resetTransferStore());
            dispatch(resetNotificationStore());
            resetStorage(user);
            clearActivityTimer(true);
            navigate(config.auth.redirectLogoutUrl);
            onSuccess && onSuccess();
        },
        [dispatch, navigate, resetStorage, successToast]
    );

    return (logoutMessage?: string, onSuccess?: () => void) => {
        if (auth()?.isLogin) {
            dispatch(setLogoutLoading(true));
            loadAuthServiceHost();
            window.axios
                .post(ENDPOINTS.LOGOUT)
                .then(() => {
                    clientLogout(logoutMessage, onSuccess);
                })
                .catch(() => {
                    errorToast(t("Échec de la déconnexion"));
                })
                .finally(() => dispatch(setLogoutLoading(false)));
        } else {
            clientLogout(logoutMessage, onSuccess);
        }
    };
};

export default useLogout;
