// import * as Sentry from "@sentry/react";
import axios, { Axios } from "axios";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import Pusher from "pusher-js";
import { registerSW } from "virtual:pwa-register";

import config from "./config";
import { refreshClientConfig } from "./helpers";
// import env from "./helpers/env.ts";

const auth = config.auth;

declare global {
    interface Window {
        axios: Axios;
        timer: ReturnType<typeof setTimeout> | undefined;
        dayjs: typeof dayjs;
        Pusher: typeof Pusher;
    }
}

/*
|--------------------------------------------------------------------------
| Axios
|--------------------------------------------------------------------------
|
| Configuration of the axios instance.
|
*/
window.axios = axios;
window.axios.defaults.baseURL = auth.apiHost + auth.apiBasePath + "/";

/*
|--------------------------------------------------------------------------
| Dayjs
|--------------------------------------------------------------------------
|
| Store dayjs instance globally.
|
*/
window.dayjs = dayjs;
window.dayjs.extend(relativeTime);
window.dayjs.extend(localizedFormat);

/*
|--------------------------------------------------------------------------
| Config
|--------------------------------------------------------------------------
|
| Store config object in storage.
|
*/
refreshClientConfig();

/*
|--------------------------------------------------------------------------
| Pusher
|--------------------------------------------------------------------------
|
| Store pusher-js instance globally
|
*/
window.Pusher = Pusher;

/*
|--------------------------------------------------------------------------
| Automatic page reload
|--------------------------------------------------------------------------
|
| Store automatic upload page code
|
*/
registerSW({ immediate: true });

/*
|--------------------------------------------------------------------------
| Sentry
|--------------------------------------------------------------------------
|
| Initialize Sentry
|
*/
/*Sentry.init({
    dsn: env("SENTRY_DNS"),
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [env("APP_URL")]
        }),
        new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false
        })
    ],
    environment: "production",
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(event) {
        return event;
    }
});*/
